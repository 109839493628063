<template>
  <v-card>
    <v-card-title>{{ $t('HealthInformation') }}</v-card-title>
    <v-card-text>
      <v-form>
        <DatePicker
          v-model="from"
          :label="$t('From')"
          :clear-able="true"
          input-class="mb-2"
        />
        <DatePicker
          v-model="to"
          :label="$t('To')"
          :clear-able="true"
          input-class="mb-2"
        />
        <v-btn
          color="primary"
          @click="handleSearch"
        >
          {{ $t('Search') }}
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-text
      v-if="!getLoadingWait"
      class="px-1"
    >
      <vue-apex-charts
        v-if="calcData.timelines && calcData.timelines.length"
        type="line"
        height="400"
        :options="calcData.chart.chartOptions"
        :series="calcData.chart.series"
      />
      <v-card-text
        v-else
      >
        {{ $t('NoData') }}
      </v-card-text>
    </v-card-text>
    <v-timeline
      v-if="!getLoadingWait"
      dense
      class="timeline-custom-dense timeline-custom-dots card-content mr-3"
    >
      <!-- Flight -->
      <v-timeline-item
        v-for="(timeline,index) in calcData.timelines"
        :key="`timeline_${index}`"
        small
        color="primary"
      >
        <v-card color="primary">
          <v-card-title>
            <small class="text-subtitle-2 white--text">{{ $t('HealthCheckOn') }} <strong>{{ timeline.time }}</strong></small>
          </v-card-title>
          <v-card-text class="pt-4 pb-3">
            <v-row
              class="px-0"
              no-gutters
            >
              <v-col
                v-for="data in timeline.statisticsData"
                :key="data.title"
                cols="6"
                sm="4"
                class="d-flex align-center px-2 mb-2"
              >
                <div>
                  <p class="text-xs mb-0">
                    {{ $t(data.title) }}
                  </p>
                  <h3 class="font-weight-bold">
                    {{ data.total }}
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <v-card-actions class="d-flex justify-center mt-4">
      <v-btn
        color="warning"
        outlined
        @click="$router.back()"
      >
        {{ $t('Back') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'

// import { ref } from '@vue/composition-api'
import {
  mdiBloodBag, mdiHexagramOutline, mdiHumanMaleHeight, mdiNaturePeople, mdiTabMinus, mdiWeightLifter,
} from '@mdi/js'

// import { getVuetify } from '@core/utils'
import themeConfig from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { DatePicker, VueApexCharts },
  data() {
    const $themeColors = this.$vuetify.theme.isDark ? themeConfig.themes.dark : themeConfig.themes.light

    return {
      from: '',
      to: '',
      themeColors: $themeColors,
    }
  },
  computed: {
    ...mapGetters('town', ['getExaminationData', 'getLoadingWait']),
    params() {
      return {
        from_date: this.from,
        to_date: this.to,
        id_user: this.$route.params.id,
      }
    },

    calcData() {
      const result = {
        timelines: [],
        chart: {
          series: [
            {
              name: this.$vuetify.lang.current === 'en' ? 'BMI' : 'BMI',
              data: [],
              type: 'line',
            },
            {
              name: this.$vuetify.lang.current === 'en' ? 'Height' : 'Chiều Cao',
              data: [],
              type: 'line',
            },
            {
              name: this.$vuetify.lang.current === 'en' ? 'Weight' : 'Cân Nặng',
              data: [],
              type: 'column',
            },
          ],
          chartOptions: {
            chart: {
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            legend: {
              show: true,
              position: 'bottom',
              horizontalAlign: 'left',
              fontSize: '14px',
              fontFamily: '"Inter", sans-serif',
            },
            markers: {
              strokeWidth: 2,
              strokeOpacity: 0.7,
              size: 4,
              hover: {
                sizeOffset: 2,
              },
              discrete: [
                {
                  seriesIndex: 1,
                  shape: 'square',
                },
              ],
            },
            colors: [this.themeColors.warning, this.themeColors.accent, this.themeColors.primary],

            // dataLabels: {
            //   enabled: false,
            // },
            stroke: {
              curve: 'smooth',
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            yaxis: [
              {
                // title: {
                //   text: $vuetify.lang.current === 'en' ? 'BMI' : 'BMI',
                // },
                opposite: true,
                seriesName: 'BMI',
                min: 10,
                max: 70,
              },
              {
                // title: {
                //   text: $vuetify.lang.current === 'en' ? 'Weight' : 'Cân Nặng',
                // },
                seriesName: 'Height',
                min: 1,
                max: 200,
              },
              {
                // title: {
                //   text: $vuetify.lang.current === 'en' ? 'Weight' : 'Cân Nặng',
                // },
                seriesName: 'Weight',
                show: false,
              },
            ],
            tooltip: {
              shared: false,
            },
            xaxis: {
              categories: [],

              // type: 'datetime',
            },
          },
        },
      }
      if (this.getExaminationData?.length) {
        this.getExaminationData.forEach(item => {
          const { height, weight, blood_pressure: bloodPressure, blood_pressure_2: bloodPressure2, body_fat: bodyFat, muscle_mass: muscleMass } = item
          const bmi = this.calcBMI(height, weight)

          result.timelines.push({
            time: this.formatDate(item.created_at * 1000),
            statisticsData: [
              {
                title: 'Height',
                total: `${height || 0}cm`,
                color: 'primary',
                icon: mdiHumanMaleHeight,
              },
              {
                title: 'Weight',
                total: `${weight || 0}kg`,
                color: 'primary',
                icon: mdiWeightLifter,
              },
              {
                title: 'BMI',
                total: bmi || 0,
                color: 'primary',
                icon: mdiTabMinus,
              },
              {
                title: 'BloodPressure',
                total: `${bloodPressure || 0}/${bloodPressure2 || 0}`,
                color: 'primary',
                icon: mdiBloodBag,
              },
              {
                title: 'BodyFat',
                total: `${bodyFat || 0}%`,
                color: 'primary',
                icon: mdiNaturePeople,
              },
              {
                title: 'MuscleMass',
                total: `${muscleMass || 0}%`,
                color: 'primary',
                icon: mdiHexagramOutline,
              },
            ],
          })
          result.chart.chartOptions.xaxis.categories.push(this.formatDate(item.created_at * 1000, 'MM/YYYY'))
          result.chart.series[0].data.push(bmi)
          result.chart.series[1].data.push(height)
          result.chart.series[2].data.push(weight)
        })

        return JSON.parse(JSON.stringify(result))
      }

      return result
    },
  },

  created() {
    this.getData()
  },
  methods: {
    ...mapActions('town', ['fetchExaminationInfo']),
    getData() {
      this.fetchExaminationInfo(this.params)
    },
    calcBMI(height, weight) {
      const heightToMass = (height * height) / 10000

      return height > 0 ? Math.round((weight / heightToMass) * 100) / 100 : null
    },
    handleSearch() {
      this.getData()
    },
  },
}
</script>
